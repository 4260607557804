import httpClient from "../httpClient";

const END_POINT = "Report";

class ReportService {
  async getReport() {
    const config = {
      method: "get",
      url: `${END_POINT}/GetReport`,
    };

    const response = await httpClient(config);
    return response.data;
  }
}
export default new ReportService();
