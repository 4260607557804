import moment from "moment";

const objToFormData = (object) => {
  let form_data = new FormData();

  for (var key in object) {
    form_data.append(key, object[key]);
  }

  return form_data;
};

const toFixed = (number, limit) => {
  if (number !== null && number !== "" && number !== undefined) {
    return Number(number).toFixed(limit);
  }
  return number;
};

const toCommas = (number, limit) => {
  if (
    number !== null &&
    number !== "" &&
    number !== undefined &&
    typeof number === "number"
  ) {
    return Number(number)
      .toFixed(limit)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number;
};

const formatDateTimeTextTH = (value, format) => {
  if (value) {
    const date = formatDateTextTH(value, format);
    const time = formatTimeTextTH(value, format);
    return `${date} ${time}`;
  }
  return value;
};

const formatTimeTextTH = (value, format) => {
  if (value) {
    return (
      moment(String(value))
        .add(543, "year")
        .locale("th")
        .format(format ? format : "H:mm") + " น."
    );
  }
  return value;
};

const formatDateTextTH = (value, format) => {
  if (value) {
    return moment(String(value))
      .add(543, "year")
      .locale("th")
      .format(format ? format : "D MMM YY");
  }
  return value;
};

// value = type Datetime
// return '2020-11-25'
const convertDateToApi = (value) => {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
  return value;
};

// value = type Datetime
// return '2020-11-25'
const convertDateToWeb = (value) => {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
  return value;
};

const formatDateTimeTextEN = (value, format, customs) => {
  if (customs) {
    const dateTime = formatDateTextEN(value, format);
    return `${dateTime}`;
  }
  if (value) {
    const date = formatDateTextEN(value, format);
    const time = formatTimeTextEN(value, format);
    return `${date} ${time}`;
  }
  return value;
};

const formatTimeTextEN = (value, format) => {
  if (value) {
    return moment(String(value)).format(format ? format : "H:mm");
  }
  return value;
};

const formatDateTextEN = (value, format) => {
  if (value) {
    return moment(String(value)).format(format ? format : "D MMM YY");
  }
  return value;
};

const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

const isTypeFileImage = (file = []) => {
  const _typeFile = ["image/jpeg", "image/jpg", "image/png"];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      return false;
    }
  });

  return true;
};

const isTypeFileAllowed = (file = [], typeFile = []) => {
  const _typeFile =
    typeFile !== null && typeFile.length > 0
      ? typeFile
      : [
          "application/pdf",
          "image/jpeg",
          "image/jpg",
          "image/png",
          // 'application/xls',
          // 'application/xlsx',
          // 'application/docx',
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      return false;
    }
  });

  return true;
};

//สามารถใช้ในส่วนของ js
export default {
  objToFormData,
  convertDateToApi,
  toFixed,
  toCommas,
  formatDateTextTH,
  formatDateTimeTextTH,
  formatTimeTextTH,
  formatDateTextEN,
  formatDateTimeTextEN,
  formatTimeTextEN,
  formatBytes,
  isTypeFileAllowed,
  isTypeFileImage,
};

//สามารถใช้ในส่วนของ html
export {
  toFixed,
  toCommas,
  formatDateTextTH,
  formatDateTimeTextTH,
  formatTimeTextTH,
  formatDateTextEN,
  formatDateTimeTextEN,
  formatTimeTextEN,
  formatBytes,
};
