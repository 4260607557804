<template>
  <div id="AppLayout" class="app-screen-large">
    <app-header></app-header>

    <!-- Content Wrapper. Contains page content -->
    <main class="content-wrapper">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import AppHeader from "./app-header/AppHeader.vue";

export default {
  name: "app-layout",
  components: {
    "app-header": AppHeader,
  },
};
</script>

<style lang="scss">
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
