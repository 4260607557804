<template>
  <div class="bar-chart-1">
    <apexchart
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div class="d-flex legend-chart justify-content-between ml-4">
      <div class="d-inline-flex align-items-center">
        <iconify
          icon="akar-icons:circle-fill"
          class="mr-1 font-graph-green"
        ></iconify>
        ดีมาก
      </div>
      <div class="d-inline-flex align-items-center">
        <iconify
          icon="akar-icons:circle-fill"
          class="mr-1 font-graph-softgreen"
        ></iconify>
        ดี
      </div>
      <div class="d-inline-flex align-items-center">
        <iconify
          icon="akar-icons:circle-fill"
          class="mr-1 font-graph-yellow"
        ></iconify>
        พอใช้
      </div>
      <div class="d-inline-flex align-items-center">
        <iconify
          icon="akar-icons:circle-fill"
          class="mr-1 font-graph-orange"
        ></iconify>
        ปรับปรุง
      </div>
      <div class="d-inline-flex align-items-center">
        <iconify
          icon="akar-icons:circle-fill"
          class="mr-1 font-graph-rad"
        ></iconify>
        ปรับปรุงเร่งด่วน
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bar-chart-1",
  props: {
    width: {
      type: Number,
      default: () => 500,
    },
    series: {
      type: Array,
      default: () => [],
    },
    result: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          background: "#fff",
          fontFamily: "Sarabun, sans-serif",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const titleShow = [
              "ด้านแหล่งน้ำดิบ",
              "ด้านระบบประปา",
              "ด้านการควบคุมการผลิตและบำรุงรักษาระบบประปา",
              "ด้านปริมาณและคุณภาพน้ำประปา",
              "ด้านการบริหารกิจการระบบประปา",
            ][dataPointIndex];

            const score = series[seriesIndex][dataPointIndex];
            const resultText = this.getResultText(score);
            const resultIcon = this.getResultIcon(score);
            const template = `<div class="chart-tooltip-custom p-2">
                <span>${titleShow}</span>
                <div class="d-flex justify-content-between">
                    <div class="result-text d-flex align-items-center">
                      <img
                        src="/images/${resultIcon}"
                        style="height: 20px; margin-right: 10px"
                      />
                      ${resultText}
                    </div>
                    <div class="result-score">${score.toFixed(2)}%</div>
                </div>
                </div>`;
            return template;
          },
        },
        xaxis: {
          categories: [
            "ปัจจัยที่ 1",
            "ปัจจัยที่ 2",
            "ปัจจัยที่ 3",
            "ปัจจัยที่ 4",
            "ปัจจัยที่ 5",
          ],
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 100,
          labels: {
            formatter: function (value) {
              return value + "%";
            },
          },
        },
        colors: [
          ({ value, seriesIndex, w }) => {
            return this.getResultColor(value);
          },
        ],
        plotOptions: {
          bar: {
            borderRadius: 12,
            horizontal: false,
            columnWidth: "30%",
            distributed: true,
          },
        },
      },
    };
  },
  created() {},
  methods: {
    findResultRange(score) {
      if (score > 90) {
        return {
          color: "#00cc00",
          text: "ดีมาก",
          icon: "Excellent.png",
        };
      } else if (score > 80) {
        return { color: "#99ff66", text: "ดี", icon: "Good.png" };
      } else if (score > 50) {
        return {
          color: "#ffff00",
          text: "พอใช้",
          icon: "Enough.png",
        };
      } else if (score > 30) {
        return {
          color: "#ff6600",
          text: "ปรับปรุง",
          icon: "So.png",
        };
      } else {
        return {
          color: "#ff0000",
          text: "ปรับปรุงเร่งด่วน",
          icon: "Bad.png",
        };
      }
    },
    getResultColor(score) {
      return this.findResultRange(score).color;
    },
    getResultText(score) {
      return this.findResultRange(score).text;
    },
    getResultIcon(score) {
      return this.findResultRange(score).icon;
    },
  },
};
</script>

<style></style>
