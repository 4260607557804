import AppTitleContent from "./cores/AppTitleContent";
import BarChart from "./cores/BarChart";

const ComponentPlugin = {
  install(Vue, options) {
    [AppTitleContent, BarChart].forEach((component) => {
      Vue.component(component.name, component);
    });
  },
};

export default ComponentPlugin;
