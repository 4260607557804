<template>
  <div id="AppLogin" class="align-items-center">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app-login",
};
</script>

<style lang="scss">
#AppLogin {
  min-height: 100vh;
}
</style>
