import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Icon } from "@iconify/vue2";
import Select2 from "v-select2-component";
import ComponentPlugin from "@/components/_ComponentPlugin";
import VueApexCharts from "vue-apexcharts";
import convertUtils from "@/utils/convert-util";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import mainUtil from "./utils/main-util";

library.add(fas);
library.add(far);

/**
 * plugin
 */
Vue.use(BootstrapVue);
Vue.use(ComponentPlugin);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("iconify", Icon);
Vue.component("Select2", Select2);
Vue.component("apexchart", VueApexCharts);

Vue.prototype.$utils = {
  convert: { ...convertUtils },
  ...mainUtil,
};

/**
 * Global mixin
 */
Vue.mixin({
  filters: convertUtils,
  methods: {
    alertAssessmentFormValidate(text = "กรุณาตอบแบบประเมินให้ครบทุกข้อค่ะ") {
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col"> 
          <div class="fontsize-h3">${text}</div>
        </div>`,
            showCancelButton: false,
            confirmButtonText: "ปิด",
            customClass: {
              container: "alert-default",
              confirmButton: "btn btn-submit",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
          })
          .then((result) => {
            resolve(true);
          });
      });
    },
    alertSuccess(title, text, timer = 1500) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-h4">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-h4">${text}</div>`);
      }

      // submit delete should be override in component
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            customClass: {
              container: `alert-default`,
            },
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertFail(title, text, timer = 1500) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ไม่สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-h4">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-h4">${text}</div>`);
      }

      // submit delete should be override in component
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            customClass: {
              container: `alert-default`,
            },
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertDeleteForm() {
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col"> 
          <div class="fontsize-h3">คุณต้องการลบรายการหรือไม่</div>
        </div>`,
            showCancelButton: true,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            customClass: {
              container: "alert-default action-btn-around",
              confirmButton: "btn btn-submit",
              cancelButton: "btn btn-cancel",
            },
            icon: "question",
            buttonsStyling: false,
            allowOutsideClick: false,
            reverseButtons: true,
          })
          .then((result) => {
            resolve(result.value || false);
          });
      });
    },
    alertDownloadFail() {
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            icon: "error",
            html: `<div class="d-col"> 
            <div class="fontsize-h3">ดาวน์โหลดไม่สำเร็จ</div>
          </div>`,
            confirmButtonText: "ตกลง",
            customClass: {
              container: "alert-default action-btn-around",
              confirmButton: "btn btn-submit",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              resolve(true);
            }
          });
      });
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
