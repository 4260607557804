import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "@/components/layouts/app/AppLayout";
import AppLogin from "@/components/layouts/login/AppLogin";
import store from "../store/index";
import { TokenService, UserCardIdService } from "@/services/storage.service";

Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
  meta: {
    empty: true,
  },
};

const routes = [
  {
    path: "",
    redirect: { name: "Login" },
  },
  {
    path: "*",
    redirect: { name: "Login" },
  },
  {
    path: "/",
    component: AppLogin,
    meta: {
      login: true,
    },
    children: [
      {
        name: "Login",
        path: "/login",
        component: () => import("@/views/Auth/Login.vue"),
      },
      {
        path: "/admin",
        name: "Admin",
        component: () => import("../views/Auth/Admin.vue"),
      },
    ],
  },
  //===>> For Admin
  {
    path: "/",
    component: AppLayout,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "menu",
        name: "MenuPage",
        component: () => import("@/views/MenuPage/Index.vue"),
      },
      {
        path: "status",
        component: EmptyParentComponent,
        children: [
          {
            path: "",
            name: "CheckStatusIndex",
            component: () => import("@/views/CheckStatus/Index.vue"),
          },
          {
            path: "summary",
            name: "StatusSummary",
            component: () => import("@/views/Assessment/Summary.vue"),
          },
          {
            path: "download",
            name: "DownloadFile",
            component: () => import("@/views/CheckStatus/DownloadFile.vue"),
          },
        ],
      },
      {
        path: "report",
        component: EmptyParentComponent,
        children: [
          {
            path: "",
            name: "ReportIndex",
            component: () => import("@/views/Report/Index.vue"),
          },
        ],
      },
      {
        path: "user",
        component: EmptyParentComponent,
        children: [
          {
            path: "",
            name: "UserList",
            component: () => import("@/views/UserManagement/List.vue"),
          },
          {
            path: ":mode",
            name: "UserFormAdd",
            component: () => import("@/views/UserManagement/Form.vue"),
          },
          {
            path: ":mode/:userId",
            name: "UserFormEdit",
            component: () => import("@/views/UserManagement/Form.vue"),
          },
          {
            path: ":mode",
            name: "UserFormChange",
            component: () => import("@/views/UserManagement/Form.vue"),
          },
        ],
      },
    ],
  },
  //===>> For User
  {
    path: "/",
    component: AppLayout,
    meta: {
      requiresAuth: true,
      isUser: true,
    },
    children: [
      {
        path: "assessment",
        component: EmptyParentComponent,
        children: [
          {
            path: "",
            name: "AssessmentList",
            component: () => import("@/views/Assessment/List.vue"),
          },
          {
            path: "all",
            name: "AssessmentListAll",
            component: () => import("@/views/Assessment/ListAll.vue"),
          },
          {
            path: "groundwater/:mode",
            name: "GroundForm",
            component: () => import("@/views/Assessment/Groundwater/Form.vue"),
          },
          {
            path: "surfacewater/:mode",
            name: "SurfaceForm",
            component: () => import("@/views/Assessment/Surfacewater/Form.vue"),
          },
          {
            path: "summary",
            name: "AssessmentSummary",
            component: () => import("@/views/Assessment/Summary.vue"),
          },
        ],
      },
    ],
  },
  //===>> For Public
  {
    path: "/",
    component: AppLayout,
    meta: {
      public: true,
      isUser: true,
    },
    children: [
      {
        path: "reportPublic",
        name: "Report",
        component: () => import("@/views/Report/Index.vue"),
        meta: {
          isPublic: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setPageLoading", true);

  let changeNext;

  const haveToken = !!TokenService.getToken();
  const haveUserCardLogin = !!UserCardIdService.getUserCardId();
  if (
    (to.matched.some((record) => record.meta.login) ||
      to.matched.some((record) => record.meta.public)) &&
    !haveUserCardLogin &&
    !haveToken
  ) {
  } else if (
    to.matched.some((record) => record.meta.login) &&
    haveUserCardLogin &&
    haveToken
  ) {
    changeNext = "AssessmentList";
  } else if (to.matched.some((record) => record.meta.login) && haveToken) {
    changeNext = "MenuPage";
  } else if (!haveUserCardLogin && !haveToken) {
    return next({ name: "Login" });
  } else {
    if (
      to.matched.some((record) => record.meta.isAdmin) &&
      haveUserCardLogin &&
      haveToken
    ) {
      changeNext = "AssessmentList";
    } else if (
      to.matched.some((record) => record.meta.isUser) &&
      haveToken &&
      !haveUserCardLogin
    ) {
      changeNext = "MenuPage";
    }
  }

  if (changeNext) {
    if (from.name === changeNext) {
      // hide loading page if from and next is same route
      store.commit("setPageLoading", false);
    }
    changeNext = { name: changeNext };
  }

  return next(changeNext);
});

router.afterEach(async (to, from, next) => {
  store.commit("setPageLoading", false);
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

export default router;
