import store from "@/store/index";

const getProfile = () => {
  const profile = store.state.account?.profile || {};
  return profile;
};

const getUserId = () => {
  return getProfile().userId || null;
};

const gotoSuggestion = () => {
  try {
    const fileName = "Recommendations after the assessment 01.pdf";
    window.open(`/files/${fileName}`, "_blank");
  } catch (e) {
    this.alertFail("เกิดข้อผิดพลาดในการดาวน์โหลด");
  }
  // try {
  //   // const fileURL =
  //   //   "http://division.dwr.go.th/bwm/index.php/2019-12-17-06-02-42/2022-07-23-12-15-45?download=161:2022-02-28-08-31-10";

  //   // const dd = document.createElement("a");
  //   // dd.href = fileURL;
  //   // document.body.appendChild(dd);
  //   // dd.click();
  //   // document.body.removeChild(dd);

  //   // window.open(fileURL, "_blank");
  // } catch (error) {
  //   console.log("gotoSuggestion", error);
  // }
};

//สามารถใช้ในส่วนของ js
export default {
  getProfile,
  getUserId,
  gotoSuggestion,
};
