import httpClient from "../httpClient";

const END_POINT = "Assessment";

class AssessmentService {
  async getSummary(qualitySurveyID) {
    const config = {
      method: "get",
      url: `${END_POINT}/Summary`,
      params: {
        QualitySurveyID: qualitySurveyID,
      },
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getDan(qualitySurveyID) {
    const config = {
      method: "get",
      url: `${END_POINT}/Dan`,
      params: {
        QualitySurveyID: qualitySurveyID,
      },
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getSurveyResultRange() {
    const config = {
      method: "get",
      url: `${END_POINT}/SurveyResultRange`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getListQualitySurvey(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetListQualitySurvey`,
      params: {
        ...param,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getDetailQualitySurvey(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetDetailQualitySurvey`,
      params: {
        QualitySurveyID: param.QualitySurveyID,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getSurfacewater() {
    const config = {
      method: "get",
      url: `${END_POINT}/Surfacewater`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getGroundwater() {
    const config = {
      method: "get",
      url: `${END_POINT}/Groundwater`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async postAssessmentFormDelete(qualitySurveyId) {
    const config = {
      method: "post",
      url: `${END_POINT}/Delete`,
      params: { QualitySurveyID: qualitySurveyId },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAssessmentFormEdit(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/Update`,
      data: { ...data },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAssessmentFormAdd(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/Add`,
      data: { ...data },
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAddFile(data) {
    let formData = new FormData();

    const { QualitySurveyId, SurveyQuestionId, File } = data;

    formData.append("QualitySurveyId", QualitySurveyId);
    formData.append("SurveyQuestionId", SurveyQuestionId);
    formData.append("File", File);

    const config = {
      method: "post",
      url: `${END_POINT}/AddFile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postEditFile(data) {
    let formData = new FormData();

    const { QualitySurveyId, SurveyQuestionId, File } = data;

    formData.append("QualitySurveyId", QualitySurveyId);
    formData.append("SurveyQuestionId", SurveyQuestionId);
    formData.append("File", File);

    const config = {
      method: "post",
      url: `${END_POINT}/EditFile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postDeleteFile(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/DeleteFile`,
      params: { ...params },
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getExportExcel(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ExportExcel`,
      params: { ...params },
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
}

export default new AssessmentService();
