import httpClient from "../httpClient";

const END_POINT = "Auth";
const END_POINT_Account = "Account";

class UserService {
  async getProfile(userId) {
    const config = {
      method: "get",
      url: `${END_POINT_Account}/GetProfile`,
      params: {
        UserId: userId,
      },
    };

    const response = await httpClient(config);

    if (response && response?.data) {
      return response.data;
    } else {
      return {};
    }
  }

  async getListUser(param) {
    const config = {
      method: "get",
      url: `${END_POINT_Account}/ListUser`,
      params: param,
    };

    const response = await httpClient(config);

    if (response && response?.data) {
      return response.data;
    } else {
      return {};
    }
  }

  async postAddProfile(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/CreateUser`,
      data: data,
    };

    const response = await httpClient(config);

    if (response && response?.data) {
      return { status: response.status, ...response.data };
    } else {
      return {};
    }
  }

  async postUpdateProfile(data) {
    const config = {
      method: "post",
      url: `${END_POINT_Account}/UpdateProfile`,
      data: data,
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return {};
    }
  }

  async postChangePassword(data) {
    const config = {
      method: "post",
      url: `${END_POINT_Account}/ChangePassword`,
      data: data,
    };

    const response = await httpClient(config);

    if (response && response?.data) {
      return { status: response.status, ...response.data };
    } else {
      return {};
    }
  }

  async postDeleteUser(userId) {
    const config = {
      method: "post",
      url: `${END_POINT_Account}/DeleteUser`,
      params: { userId: userId },
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return {};
    }
  }

  async postSetPassword(data) {
    const config = {
      method: "post",
      url: `${END_POINT_Account}/SetPassword`,
      data: data,
    };

    const response = await httpClient(config);

    if (response && response?.data) {
      return response.data;
    } else {
      return {};
    }
  }

  async getIsExistUserName(username) {
    const config = {
      method: "get",
      url: `${END_POINT_Account}/IsExistUserName`,
      params: { UserName: username },
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return {};
    }
  }

  async getIsExistEmail(email) {
    const config = {
      method: "get",
      url: `${END_POINT_Account}/IsExistEmail`,
      params: { Email: email },
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return {};
    }
  }

  async getCheckEmail(email, userId) {
    const config = {
      method: "get",
      url: `${END_POINT_Account}/CheckEmail`,
      params: { Email: email, UserId: userId },
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return {};
    }
  }
}

export default new UserService();
