<template>
  <div id="app">
    <div v-if="isPageLoading" class="loading-page">
      <b-spinner
        v-show="isPageLoading"
        class="pre-loader"
        variant="info"
        label="Loading..."
        style="width: 5rem; height: 5rem"
      ></b-spinner>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app",
  created() {},
  computed: {
    ...mapGetters(["isPageLoading"]),
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-page {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(white, 0.5);
  z-index: 10000;
}
</style>
