<template>
  <div class="navigation fontsize-sm font-headgray">
    <b-breadcrumb class="w-100">
      <b-breadcrumb-item
        class="align-items-center"
        v-for="item in breadcrumbs"
        :key="item.text"
        :to="{ name: item.to }"
        :active="item.active"
      >
        <template v-if="item.icon">
          <iconify :icon="item.icon" class="fontsize-h5 mr-2" />
        </template>

        <span class="align-middle">{{ item.text }}</span>
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "app-title-content",
  props: {
    name: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    root: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      currentRouteName: null,
      breadcrumbs: [],
      rootBc: {
        text: "หน้าหลัก",
        to: "MenuPage",
        icon: "fa-solid:home",
      },
    };
  },
  created() {
    const _items =
      this.items.map((m, index) => {
        return { ...m, active: index === this.items.length - 1 };
      }) || [];

    let _root = [];
    if (this.root !== null) {
      const active =
        this.root.active === null || this.root.active === undefined
          ? true
          : this.root.active;

      _root = [
        {
          text: this.root.text || this.rootBc.text,
          icon: this.root.icon || this.rootBc.icon,
          to: this.root.to || this.rootBc.to,
          active: active,
        },
      ];
    } else {
      _root = [{ ...this.rootBc }];
    }

    this.breadcrumbs = _items.length === 0 ? _root : _root.concat(..._items);
  },
  watch: {
    $route(route) {
      this.currentRouteName = route.name;
    },
  },
};
</script>

<style></style>
