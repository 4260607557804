import httpClient from "../httpClient";

const END_POINT = "Filter";

class FilterService {
  async getListZone() {
    return [
      {
        id: "47P",
        text: "47P",
      },
      {
        id: "48P",
        text: "48P",
      },
    ];
  }

  async getListPWAType() {
    const config = {
      method: "get",
      url: `${END_POINT}/PWAType`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      const { data, total } = { ...response.data };
      return {
        total,
        data: data?.map((m) => {
          return { ...m, text: m.name };
        }),
      };
    } else {
      return response || {};
    }
  }

  async getListDepartment(departmentId = null, isReginal = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/Department`,
      params: {
        departmentID: departmentId,
        isReginal: isReginal,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      const { data, total } = { ...response.data };
      return {
        total,
        data: data?.map((m) => {
          return { ...m, text: m.name };
        }),
      };
    } else {
      return response || {};
    }
  }

  async getListProvince(departmentId = null, provinceId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/Province`,
      params: {
        provinceID: provinceId,
        departmentID: departmentId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      const { data, total } = { ...response.data };
      return {
        total,
        data: data?.map((m) => {
          return { ...m, text: m.name };
        }),
      };
    } else {
      return response || {};
    }
  }

  async getListAmphur(provinceId = null, amphorId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/Amphor`,
      params: {
        provinceID: provinceId,
        amphorID: amphorId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      const { data, total } = { ...response.data };
      return {
        total,
        data: data?.map((m) => {
          return { ...m, text: m.name };
        }),
      };
    } else {
      return response || {};
    }
  }

  async getListTambol(amphorId = null, tambolId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/Tambol`,
      params: {
        amphorID: amphorId,
        tambolID: tambolId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      const { data, total } = { ...response.data };
      return {
        total,
        data: data?.map((m) => {
          return { ...m, text: m.name };
        }),
      };
    } else {
      return response || {};
    }
  }

  async getListVillage(tambolId = null, villageId = null) {
    const config = {
      method: "get",
      url: `${END_POINT}/Village`,
      params: {
        tambolID: tambolId,
        villageID: villageId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      const { data, total } = { ...response.data };
      return {
        total,
        data: data?.map((m) => {
          return { ...m, text: m.name };
        }),
      };
    } else {
      return response || {};
    }
  }
}

export default new FilterService();
