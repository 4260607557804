const TOKEN_STRING = "Bearer";
const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

const USERCARD_KEY = "usercard_id";
import SecureLS from "secure-ls";
const lsCard = new SecureLS({
  encodingType: "Base64",
  isCompression: false,
});

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

const UserCardIdService = {
  getUserCardId() {
    return lsCard.get(USERCARD_KEY);
  },

  saveUserCardId(data) {
    lsCard.set(USERCARD_KEY, data);
  },

  removeUserCardId() {
    lsCard.remove(USERCARD_KEY);
  },
};

export { TokenService, TOKEN_STRING, UserCardIdService };
