<template>
  <div class="app-header">
    <div class="d-flex align-items-center pr-3 main-header">
      <div class="header-project px-3 d-flex align-items-center">
        <img
          class="header-logo-img"
          src="/images/logo.png"
          style="height: 47px"
        />
        <span class="px-2 fontsize-h6">กรมทรัพยากรน้ำ</span>
      </div>

      <div class="flex-fill px-3 fontsize-h5 align-items-center">
        ระบบประเมินคุณภาพระบบประปาหมู่บ้าน กรมทรัพยากรน้ำ
      </div>
      <div class="user-display rounded-pill" v-if="!isPublic">
        <b-avatar
          class="login-image"
          size="30px"
          :src="userImage"
          onerror="javascript:this.src='/images/default-profile.png'"
        ></b-avatar>
        <span
          class="mx-2 login-name align-middle text-center"
          style="display: inline-block; min-width: 100px"
          >{{ usernameLogin }}</span
        >
      </div>
      <div v-if="!isPublic">
        <b-navbar-nav class="mx-2 nav-logout">
          <b-nav-item-dropdown right class="nav-dropdown-logout">
            <template #button-content>
              <iconify icon="ci:hamburger"></iconify>
            </template>
            <b-dropdown-item href="#" @click="getLogout"
              ><font-awesome-icon
                class="font-deeporange mr-2"
                :icon="['fas', 'power-off']"
              />
              ออกจากระบบ</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changePassword" v-if="isAdmin"
              ><font-awesome-icon
                class="font-blueinfo mr-2"
                :icon="['fas', 'user']"
              />
              เปลี่ยนรหัสผ่าน</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
  </div>
</template>

<script>
import { UserCardIdService } from "@/services/storage.service";

export default {
  name: "app-header",
  data() {
    return {
      usernameLogin: null,
      userImage: null,
      isAdmin: false,
      isPublic: false,
    };
  },
  async created() {
    this.checkIsAdmin();
    this.checkIsPublic();
    this.usernameLogin = this.isAdmin
      ? this.getAdminName()
      : UserCardIdService.getUserCardId();
  },
  methods: {
    getLogout() {
      if (this.isAdmin) {
        this.$store.dispatch("account/logout").then((res) => {
          this.$router.push({ name: "Login" });
        });
      } else {
        this.$store.dispatch("account/logout").then((res) => {
          this.$store
            .dispatch("usercard/logout", this.username)
            .then(
              (res) => {
                if (res) {
                  this.$store.commit("usercard/saveLocalUserCard");
                  this.$router.push({ name: "Login" });
                }
              },
              (error) => {}
            )
            .catch((error) => {});
        });
      }
    },
    changePassword() {
      this.$router.push({
        name: "UserFormChange",
        params: {
          mode: "change",
        },
      });
    },
    checkIsAdmin() {
      const _router = this.$route;
      this.isAdmin = _router.matched.some((record) => record.meta.isAdmin);
    },
    checkIsPublic() {
      const _router = this.$route;
      this.isPublic = _router.matched.some((record) => record.meta.isPublic);
    },
    getAdminName() {
      const profile = this.$utils.getProfile();

      if (profile && profile.firstName && profile.lastName) {
        return profile.firstName + " " + profile.lastName;
      } else if (profile.firstName || profile.lastName) {
        return profile.firstName || "" + profile.lastName || "";
      }

      return "ไม่มีชื่อผู้ใช้งาน";
    },
  },
};
</script>

<style lang="scss"></style>
