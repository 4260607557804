import httpClient from "../httpClient";

const END_POINT = "Status";

class StatusService {
  async getStatus() {
    const config = {
      method: "get",
      url: `${END_POINT}/GetStatus`,
    };

    const response = await httpClient(config);
    return response.data;
  }
}
export default new StatusService();
