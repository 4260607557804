import { UserCardIdService } from "@/services/storage.service";

const state = {
  usercardId: null,
};

const actions = {
  login({ commit }, data) {
    commit("loginSuccessUser", data);
    return Promise.resolve(true);
  },
  logout({ commit }) {
    commit("logoutSuccessUser");
    return Promise.resolve(true);
  },
};

const mutations = {
  loginSuccessUser(state, user) {
    state.usercardId = user;
  },
  loginFailureUser(state) {
    state.usercardId = null;
  },
  logoutSuccessUser(state) {
    state.usercardId = null;
  },
  logoutFailureUser(state) {
    state.usercardId = null;
  },
  saveLocalUserCard(state) {
    if (state.usercardId) {
      UserCardIdService.saveUserCardId(state.usercardId);
    } else {
      UserCardIdService.removeUserCardId();
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
