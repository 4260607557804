import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import app from "./app.module";
import account from "./account.module";
import usercard from "./usercard.module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "dwraccount",
      paths: ["account"],
    }),
  ],
  modules: {
    app,
    account: account,
    usercard: usercard,
  },
});
